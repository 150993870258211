body {
    margin: 0;
    font-family: var(--fontFamily), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor);
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.initalLoadingCnt {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--primColor);
    font-weight: 500;
}

.customContainer {
    padding-inline-start: 50px !important;
    padding-inline-end: 50px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-left: auto !important;
    margin-right: auto !important;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 30px;
    /* width: 100%; */
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.homeInitialLoader {
    margin: 40px 0 100px 0;
}

.homeInitialLoader .adsBannerSkeleton .absItem .skeleton.title {
    height: 400px;
}

.homeInitialLoader .adsBannerSkeleton .absItem .skeleton.title {
    width: 100%;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 280px;
    width: 100%;
    margin-inline-end: 25px;
}

.search .searchLt {
    position: sticky;
    top: 10px;
}

.dashboard .dashboardLt .sideNav {
    position: sticky;
    top: 10px;
}

.search .searchRt,
.dashboard .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 305px);
    overflow: hidden;
}

.search .searchRt {
    max-width: calc(100% - 0px);
}

.search .searchResults.Grid,
.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.search .searchMiscFilters .customSelect .MuiFormControl-root.MuiTextField-root {
    margin-top: 5px;
}

.search .searchMiscFilters .customSelect,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 25px;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-inline-end: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

.dpTable table {
    color: #212529;
}

.dpTable table thead th {
    background: var(--darkBrown);
    color: #fff;
    font-weight: 400;
}

.dpTable table .MuiFormControlLabel-root {
    margin: 0;
}

.dpTable table td.credit {
    color: #4caf50;
    font-weight: 500;
    display: flex;
}

.dpTable table td.debit {
    color: #f44336;
    font-weight: 500;
    display: flex;
}

.dpTable table td > .material-icons {
    padding-inline-end: 5px;
}

.dpTable .table th {
    border: none;
}

.dpTable .table td,
.dpTable .table th {
    vertical-align: middle;
}

.searchSkeletonBody {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.searchSkeletonBody > div {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.material-icons.rtl,
.rtlImage {
    transform: rotateY(180deg);
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 15px;
}

.loadingCnt .gridViewSkeleton .skeletonWrapper {
    box-shadow: none;
}

.loader-center-wrapper .loadingCnt {
    grid-template-columns: auto;
}

.userInitials.small {
    width: 30px;
    height: 30px;
    font-size: 14px;
    background: #3d3d3d;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInitials.large {
    width: 90px;
    height: 90px;
    font-size: 28px;
    font-weight: 700;
    background: #f1f3f6;
    border-radius: 50%;
    color: #9ca8b9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.regDivider {
    margin-bottom: 35px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 50vmax;
    max-width: 800px;
    padding: 20px;
}

.pvTimerView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pvTimerView h6 {
    font-size: 14px;
    font-weight: 500;
    color: var(--primColor);
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search .searchMiscFilters .customSelect {
    min-width: 142px;
}

.search .searchMiscFilters .srtBy {
    font-size: 18px;
    font-weight: 500;
}
.pvTimerView h6:not(:last-child) {
    /* margin-right: 25px; */
}

.pvTimerView h6 span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: var(--primColor);
    text-transform: uppercase;
}

.savedBankCnt {
    display: grid;
    margin-top: 25px;
    grid-gap: 30px;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.savedBankCnt .savedBank {
    padding: 15px;
    text-align: right;
    background: #ebebeb;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin: 0;
    background-image: url('./assets/images/bankbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 164px;
    /* background-image: linear-gradient(to right, #b0fbff 0%, #4facfe 100%); */
}

.savedBankCnt .savedBank .material-icons {
    font-size: 45px;
    color: var(--primColor);
}

.savedBankCnt .savedBank h4 {
    font-size: 15px;
    margin: 10px 0 5px;
    font-weight: 500;
    color: #000;
}

.savedBankCnt .savedBank h5 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 600;
    color: #333;
    letter-spacing: 4px;
}

.savedBankCnt .savedBank.addNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 2px dashed #ddd;
    cursor: pointer;
    min-width: 277px;
    min-height: 164px;
}

.savedBankCnt .savedBank.addNew .material-icons {
    margin-bottom: 10px;
    color: #cccccc;
}

.savedBankCnt .savedBank.addNew h6 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #4d4d4d;
}

.savedBankCnt .savedBank h4 span,
.savedBankCnt .savedBank h5 span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: var(--primColor);
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.savedBankCnt .savedBank .sc-primary-label {
    background: #f38723;
    padding: 5px 15px;
    padding-right: 25px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-size: 14px;
    display: inline-block;
    margin-left: 0;
    color: #fff;
    transform: translateX(15px);
    margin-bottom: 5px;
}

.savedBankCnt .savedBank .edtBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
}

.savedBankCnt .savedBank .dltBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: #dc3545;
}

.fullScreenPopup .MuiToolbar-root.fspToolbar {
    max-width: 1600px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.fullScreenPopup .MuiToolbar-root.fspToolbar .MuiTypography-root {
    margin-left: 0;
}

.heroCarousel .bannerInner {
    z-index: 1;
}

.customDialog.pickupModal .MuiPaper-root.MuiDialog-paper {
    min-width: 500px;
}

.productVwDialog.customDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 928px;
}

.network-issue {
    z-index: 1001;
    text-align: center;
    background: var(--secColor);
    color: #fff;
    position: sticky;
    padding: 5px 10px;
    margin: 0;
    top: 0;
    width: 100%;
}

.network-reload {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1145px) {
    .search .searchLt,
    .dashboard .dashboardLt {
        max-width: 250px;
    }

    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-inline-start: 15px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff2e7;
        border-color: rgb(25 157 229 / 20%) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

@media (max-width: 928px) {
    .productVwDialog.customDialog .MuiPaper-root.MuiDialog-paper {
        min-width: calc(100% - 64px);
        width: auto;
    }
}

@media (max-width: 600px) {
    .search .searchMiscFilters .srtBy {
        font-size: 14px;
        white-space: nowrap;
    }
}

@media (max-width: 500px) {
    .customDialog.pickupModal .MuiPaper-root.MuiDialog-paper {
        min-width: 300px;
    }
    .network-issue {
        font-size: 12px;
    }
}

@media (max-width: 425px) {
    .sortLineUP {
        flex-wrap: wrap;
    }
    .search .searchMiscFilters .customSelect {
        min-width: 100%;
    }
}

input[value='']::-webkit-datetime-edit {
    color: transparent;
}

input:focus::-webkit-datetime-edit {
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* @media (max-width: 991px) {
    .customContainer {
        padding-inline-start: 30px !important;
        padding-inline-end: 30px !important;
    }
} */

.MuiDivider-root {
    /* background-color: rgb(149 208 242 / 55%) !important; */
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }
}

@media (max-width: 600px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }
}

@media (max-width: 445px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }

    .pagination-wrapper h6 {
        font-size: 14px;
    }
}

@media print {
    .noPrint {
        display: none !important;
    }
}

@media (max-width: 1023px) {
    .drawer-open .App {
        max-height: 100vh;
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .bidding-row .MuiCheckbox-root {
        padding-left: 0 0 0 5px;
    }

    .bidding-row .MuiFormControlLabel-root {
        margin: 0;
    }

    .bidding-row .MuiListItem-gutters {
        padding: 0;
        font-size: 12px;
    }
    .productTabHrd .MuiTabs-fixed {
        overflow-y: auto !important;
    }

    .verifyPhone.customDialog .MuiPaper-root.MuiDialog-paper {
        min-width: 380px !important;
        max-width: 380px !important;
    }
    html,
    body {
        max-width: 100vw;
        overflow-x: hidden;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }
}
