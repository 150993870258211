footer {
    /* padding-top: 30px; */
    padding-bottom: 0px;
    text-align: start;
    color: var(--textColor);
    background-color: var(--greyBg);
}

footer .footBody .footLinks ul:nth-child(3) li a,
footer .footBody .footLinks ul:nth-child(3) li {
    color: var(--darkBrown);
}

footer .footBody .footLinks .footLinkHdr {
}

footer .footBody .footLinks {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

footer .footBody {
    display: grid;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: 20% 77%;
    grid-gap: 35px;
    padding-bottom: 50px;
}

footer .footLt object,
footer .footLt img {
    max-width: 200px;
    width: auto;
    height: auto;
}

/* footer .footLogo img:first-child {
    margin-inline-end: 30px;
    width: 160px;
    object-fit: contain;
} */

footer .footLogo img {
    width: 200px;
    object-fit: contain;
    margin-right: auto;
}

footer h2 {
    font-weight: bold;
    margin-bottom: 19px;
    font-size: 16px;
    color: var(--darkBrown);
    font-weight: 600;
    text-transform: uppercase;
}

footer ul {
    padding-inline-start: 0;
    margin-bottom: 0;
    list-style-type: none;
}

footer ul li a.mailto {
    text-transform: lowercase;
}

footer ul li a {
    color: var(--textColor);
    position: relative;
    font-size: 14px;
    font-weight: 500;
}

footer .footLinks ul li object {
    margin-right: 11px;
    color: var(--darkBrown);
}
footer .footLinks ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

footer .footLinks ul li:nth-child(2) {
    line-height: normal;
    padding-bottom: 14px;
}

footer .footSocial .worldIcon {
    font-size: 30px;
}

footer .footSocial img {
    width: 25px;
    height: 25px;
}

footer ul li a:hover {
    text-decoration: none;
    color: var(--textColor);
}

footer ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--textColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

footer ul li a:hover:after {
    width: 100%;
    left: 0;
}

footer .footLinks ul:not(:last-child) {
    /* margin-inline-end: 75px; */
    width: 38%;
}

footer .footMisc .footSocial li:not(:last-child) {
    margin-inline-end: 30px;
}

footer .footMisc .footSubscribe form {
    background: #3a658e;
    border-radius: 5px;
    height: auto;
    flex-wrap: nowrap;
}

footer .footMisc .footSubscribe form {
    margin-bottom: 5px;
}

footer .footMisc .footSubscribe form .customInput {
    margin-bottom: 0;
}

footer .footMisc .footSubscribe form .customInput {
    background: inherit;
    border: none;
    color: var(--textColor);
    height: inherit;
}

.footSubscribe .MuiFormLabel-root {
    color: var(--textColor);
}

footer .footMisc .footSubscribe form .subsInp {
    align-self: stretch;
}

footer .footMisc .footSubscribe form button {
    height: auto;
    align-self: stretch;
}

footer .footMisc .footSubscribe form .MuiFormHelperText-root.Mui-error {
    color: #000000;
    background: #ff616163;
    margin: 0;
    padding: 3px 10px;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:before {
    border-bottom: transparent;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:after {
    border-bottom: 1px solid var(--primColor);
}

footer .footMisc .footSubscribe form input:focus {
    box-shadow: none;
    border: none;
}

footer .footMisc .footSubscribe form input::placeholder {
    color: var(--textColor);
}

footer .footMisc .footSubscribe form button {
    color: var(--textColor);
    display: flex;
}

footer .footMisc .footSubscribe small {
    color: var(--textColor);
}

footer .footCopyright {
    margin: 0;
    color: var(--white);
    text-align: center;
    font-size: 12px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--darkBrown);
}

footer .poweredBy {
    color: var(--textColor);
    margin: 15px 0 0 0;
    font-size: 14px;
    text-align: right;
    font-weight: normal;
}

footer .hashTag {
    color: var(--textColor);
    margin: 0 0 15px 0;
    font-size: 14px;
    text-align: right;
    font-weight: normal;
}

footer .footCnt {
    border-top: 1px solid var(--secColor);
    padding-top: 25px;
}

footer .footLogo {
    margin-bottom: 40px;
}

@media (max-width: 1200px) {
    .footCnt .footLogo {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0 auto 40px;
    }
}

@media (max-width: 991px) {
    footer .footLt object,
    footer .footLt img {
        max-width: 150px;
        object-fit: scale-down;
    }
    footer .footBody {
        grid-template-columns: 18% 80%;
        grid-gap: 20px;
    }
    footer .footLinks ul:not(:last-child) {
        padding-right: 20px;
    }
    footer .footLinks ul:not(:last-child) {
        width: fit-content;
    }
}

@media (max-width: 830px) {
    footer .footBody {
        grid-template-columns: 100%;
        grid-gap: 20px;
    }
}

@media (max-width: 767px) {
    .footCnt .footLinks,
    .footCnt .footMisc {
        width: 100%;
    }
    footer .footMisc .footSubscribe form .subsInp {
        width: 90%;
    }
    footer .footMisc .footSubscribe button {
        min-width: auto;
    }
    footer .footSocial {
        justify-content: center !important;
        margin: 10px auto;
    }
    footer .footCopyright {
        margin-top: 25px;
        text-align: center !important;
    }
    footer .footMisc .footSubscribe form {
        flex-wrap: nowrap;
    }
    footer .hashTag {
        margin-top: 10px;
    }
    footer .hashTag,
    footer .poweredBy {
        text-align: center;
    }
    footer .footLinks ul {
        justify-content: space-around;
        margin-bottom: 20px;
    }
    footer .footLogo img {
        margin-left: auto;
    }
}

@media (max-width: 600px) {
    .headerBottom .MuiButtonBase-root a {
        font-size: 13px;
    }
    footer .footBody .footLt {
        text-align: center;
    }
    footer .footBody {
        grid-template-columns: 100%;
        padding-bottom: 0;
    }
}

@media (max-width: 445px) {
    footer ul li a {
        font-size: 13px;
    }
    footer .footMisc .footSubscribe small {
        text-align: center;
        width: 100%;
        display: block;
    }
    footer .footLinks ul:not(:last-child) {
        margin-inline-end: 35px;
    }
}
