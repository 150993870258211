.loginCnt {
    background: #eef0f4;
    padding: 60px 0;
}

.login {
    max-width: 550px;
    background: #fff;
    margin: 30px auto;
    border-radius: 5px;
    padding: 45px;
}

.remebrMe .forPass {
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    text-decoration: underline;
    color: var(--accentColor);
}

p.forPass .aPass {
    color: var(--accentColor);
    margin-left: 0px;
    text-transform: capitalize;
    text-decoration: underline;
    font-weight: 400;
    padding: 0px 3px;
    line-height: normal;
}

p.forPass .MuiButton-label {
    margin-left: 0;
    padding-left: 2px;
    justify-content: flex-start;
}

p.forPass {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--darkBrown);
    margin-top: 17px;
}

.remebrMe .MuiFormControlLabel-label {
    font-size: 14px;
}

.remebrMe .aPass {
    color: var(--accentColor);
    text-transform: capitalize;
    text-decoration: underline;
    font-weight: 400;
}

.remebrMe {
    margin-bottom: 10px;
    margin-top: 0px;
}

.remebrMe .customCheckbox .MuiFormControlLabel-root {
    margin-bottom: 0;
}

.remebrMe .MuiTypography-root .MuiListItem-root {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.login .brandLogo {
    margin-bottom: 35px;
    width: 200px;
}

.login h1 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    text-transform: uppercase;
}

.login h2 {
    font-size: 18px;
    margin-bottom: 35px;
}

.login .loginActBox .MuiButtonBase-root.MuiListItem-root {
    padding-inline-start: 0;
}

.login .loginActBox .primButton {
    width: 160px;
}

.login .MuiDivider-root {
    margin: 25px 0;
}

.login .primButton {
    width: 200px;
    margin-left: auto;
}

.login.forgotPassword .primButton {
    width: 250px;
}

/* .login.resetPassword h2{
  margin-bottom: 35px;
} */

.login .loginMiscAction a {
    color: #333;
}

@media (max-width: 767px) {
    .remebrMe .customCheckbox .MuiFormControlLabel-root {
        margin-right: 0;
    }

    .remebrMe .MuiFormControlLabel-label,
    .loginActBox .MuiButton-label {
        font-size: 13px;
        line-height: 24px;
    }

    .remebrMe .MuiCheckbox-root {
        padding: 0 9px;
    }
    .login {
        border: none;
        margin: 30px auto;
        padding: 30px;
    }
    .login h1 {
        font-size: 24px;
    }
    .login h1 {
        font-size: 16px;
    }
    .login .brandLogo {
        width: 150px;
    }
    .login .loginActBox {
        flex-wrap: wrap;
    }
    .login .loginActBox .primButton {
        width: 100%;
    }
}

@media (max-width: 445px) {
    .login {
        padding: 15px;
    }
    .login .loginMiscAction a {
        font-size: 13px;
    }
}

@media (max-width: 380px) {
    .login h1 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .remebrMe .MuiFormControlLabel-label,
    .loginActBox .MuiButton-label {
        font-size: 11px;
    }
}
