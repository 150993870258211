.registrationCnt {
    background: #eef0f4;
}

.registration {
    border-radius: 10px;
    padding: 0px;
    /* box-shadow: 0px 3px 30px rgb(43 43 43 / 10%); */
}

.registration .plBox {
    margin: 0 auto;
}

.registration .regInfo {
    background: #fff;
    padding: 45px 35px 30px;
}

.registration .plBox h2 {
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
}

.registration .plBox h4 {
    font-weight: 400;
}

.registration .brandLogo {
    width: 200px;
    margin-bottom: 30px;
}

@media (max-width: 1024px) {
    .registration {
        border-top: 1px solid #e9e9e9;
        box-shadow: none;
    }
}

@media (max-width: 767px) {
    .registration {
        border: none;
        padding: 0;
        margin: 0;
    }
    .registration .regInfo {
        padding: 20px 0 0;
        position: absolute;
        width: 100%;
        min-width: 100%;
        left: 0;
        z-index: 99;
        background: #fff;
        top: 0;
    }
}
